export function autoResizeTextArea(selector, maxHeight) {
    const element = document.querySelector(selector);
    if (element) {
        element.style.boxSizing = 'border-box';
        const offset = element.offsetHeight - element.clientHeight;
        element.addEventListener('input', (event) => {
            const { height } = event.target.getBoundingClientRect();
            if (height >= maxHeight) return;
            /* eslint-disable no-param-reassign */
            event.target.style.height = 'auto';
            event.target.style.height = `${event.target.scrollHeight + offset}px`;
            /* eslint-enable no-param-reassign */
        });
    }
}

export { autoResizeTextArea as default };
