<template>
    <div class="flex flex-col h-full justify-between">
        <div class="flex flex-col subject-header">
            <div class="flex flex-row text-gray-800 pt-3 items-center">
                <svg
                    @click="closeStartChat"
                    class="h-6 fill-current pl-3 pr-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
                <span class="text-lg text-center pl-2">New conversation</span>
            </div>
            <div class="flex flex-row items-center text-gray-800 pt-4 pb-3">
                <span class="pl-4 pr-3">To:</span>
                <input
                    ref="recipientInput"
                    type="text"
                    placeholder="Type a shortcode"
                    v-model.trim="recipient"
                    class="outline-none py-0 pl-0 pr-3 w-full"
                />
            </div>
        </div>
        <div class="p-3">
            <textarea
                class="js-message appearance-none block text-gray-800 w-full rounded-lg border border-gray-400 p-3 resize-none leading-tight focus:outline-none"
                placeholder="Text message"
                v-model.trim="message"
                @keyup.enter.prevent="sendChat">
            </textarea>
        </div>
    </div>
</template>

<script>
import { autoResizeTextArea } from '../../../utils/dom';

export default {
    name: 'StartChat',

    props: {
        sendMessage: {
            type: Function,
            default: () => () => {},
        },

        closeStartChat: {
            type: Function,
            default: () => () => {},
        },
    },

    data() {
        return {
            recipient: '',
            message: '',
        };
    },

    mounted() {
        autoResizeTextArea('.js-message', 150);
        this.$refs.recipientInput.focus();
    },

    beforeDestroy() {
        this.closeStartChat();
    },

    methods: {
        sendChat() {
            if (this.recipient && this.message) {
                this.sendMessage({
                    to: this.recipient,
                    message: this.message,
                });
            }
        },
    },
};
</script>

<style lang="postcss" scoped>
.subject-header {
    box-shadow: 0 6px 11px -3px rgba(0, 0, 0, 0.1), 0 2px 5px -1px rgba(0, 0, 0, 0.06);
}
</style>
